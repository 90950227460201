/* The height of everything above the auction car images needs to be known to
put them at a fixed position */
:root {
    --auction-car-card-body-v-padding: 10px;
}

div.AuctionCarCardBody {
    /* don't use horizontal padding, container columns have it */
    padding: var(--auction-car-card-body-v-padding) 0px;
}

/* Left-align tables */
.AuctionCarCardBody tbody td {
    text-align: left;
}

.CarImagesContainer {
    /* Having the images not scroll with the whole page, and render with the
    same width and at the same vertical position as normal, requires using
    sticky instead of fixed positioning. The requirement that scrolling while
    hovering over the images should scroll the rest of the page also means we
    can't place the images normally then use overflow: auto for the right
    column of the page. */
    position: sticky;
    top: calc(
        var(--auction-car-banner-height) +
        var(--auction-car-card-body-v-padding)
    );
}

/* Add space after each item in the right column, except the last one */
.AuctionDetailsCol > table, .AuctionDetailsCol > div {
    margin-bottom: 16px;
}
.AuctionDetailsCol > table:last-child, .AuctionDetailsCol > div:last-child {
    margin-bottom: 0px;
}

/* Make the left column of the translation info bold to match the format of
the AuctionCarDetails table */
.AuctionDetailsCol .TranslationTable tbody tr > td:first-child {
    font-weight: bold;
}

.AuctionDetailsCol > h2 {
    font-size: 1.25rem;
    text-align: center;
}

.toast.AuctionTimeToast {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    background-color: white;
    max-width: fit-content;
}

.AuctionTimeToast p {
    /* Don't want extra space before bottom of modal due to paragraph tag */
    margin-block-end: 0px;
}

:root {
    /* The height of the banner needs to be known so we can vertically position
    the auction sheet and car images */
    --auction-car-banner-height: 60px;
    /* When collapsed due to scrolling down, the banner will appear this tall */
    --auction-car-banner-collapsed-height: 10px;
}

.navbar.AuctionActionBanner {
    padding: 0px;
    width: 100%;
    height: var(--auction-car-banner-height);
    /* Use CSS to make it sticky instead of the Navbar's `sticky="top"` prop
    because the latter applies `z-index: 1020`, causing it to block the admin
    dropdown from the site's main navbar */
    position: sticky;
    top: 0px;
    z-index: 100;
    /* Javascript will modify the top property to shrink the navbar, so this
    will make it transition smoothly */
    transition: top 0.25s;

    /* The value for `top` we want to use when we get shrunk. This is only
    accessed by JS, but defining it here allows the JS code to not require a
    magic constant. By setting `top` to X - height, the visible part of the
    banner will be X pixels tall. */
    --hidden-top: calc(
        var(--auction-car-banner-collapsed-height)
        - var(--auction-car-banner-height)
    );
}

.AuctionActionBanner .navbar-nav {
    width: 100%;
    height: 100%;
    justify-content: center;

    & .nav-item {
        height: 100%;
        & div {
            height: 100%;
        }
    }
}

.AuctionActionBanner button {
    height: 100%;
    overflow: hidden;
    /* Default top/bottom padding is 6, but default left/right is 12. Make
    left/right 6 as well so "Create translation for user" fits in 2 lines. */
    padding: 6px;
}

.AuctionActionBanner .nav-item p {
    margin-block-end: 0px;
}
